import * as React from "react"
import Layout from "../components/layout"
import Profile from "../components/profile"
import ProfileImage from "../components/profileImage"
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"


const Member = ({ data }) => {
  data = data.datoCmsTeam
  return (
    <Layout>
      <div className="p40 flex m-wrap m-reverse">
        <div className="w-50 m-100 m-p0 pr20">
          <Profile data={data} />
        </div>
        <div className="w-50 m-100 m-p0 pl20">
          <ProfileImage image={data.image} />
        </div>
      </div>
      <Spacer className='m-show' />
      <Cta />
    </Layout>
  )
}

export const Head = ({ data }) => <Seo title={data.datoCmsTeam.name} />

export default Member

export const query = graphql`
  query TeamMember($id: String!) {
    datoCmsTeam(id: { eq: $id }) {
      name
      jobPosition
      quotation
      education
      content
      image {
        gatsbyImageData
      }
      dropdowns {
        content
        title
      }
    }
  }
`
