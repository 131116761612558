import * as React from "react"
import Dropdowns from "../components/dropdowns"
import { Link } from "gatsby"

const Profile = data => {
  data = data.data
  return (
    <div className="profile max-650">
      <div className="header pb20">
        <p className="op-50">{data.jobPosition}</p>
        <h1 className="mt80 h3 m0 mb20 m-mb40">{data.name}</h1>
        <div
          className="op-50 mb20 m-mb40"
          dangerouslySetInnerHTML={{ __html: data.education }}
        />
        <div className="bb1 b-grey" />
      </div>
      <div className="content">
        <p className="h3 m-h4 m0 mt20 mb80 m-mb40">{data.quotation}</p>
        <div
          className="medium mb80"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="bb1 b-grey" />
      </div>
      <div className="get-to-know mt80 mb80 pb40">
        <p className="m0 op-50 mb40">Get to know {data.name.split(" ")[0]}</p>
        <Dropdowns dropdowns={data.dropdowns} isProfile/>
      </div>
      <div className="pt20 bt1 b-grey flex">
        <Link to="studio" className="link medium ">
          See the full team <span className="ml20 arrow" />
        </Link>
        <Link to="studio" className="link medium mla">
          Next
          <span className="ml20 arrow" />
        </Link>
      </div>
      
    </div>
  )
}

export default Profile
